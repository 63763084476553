import React from 'react';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="home-page">
      <section className="hero">
        <div className="container">
          <h1>Bienvenue sur TrailCityRun</h1>
          <p>Partagez la passion de la course à pied avec le gang de girafes</p>
        </div>
      </section>

      <section className="featured-content">
        <div className="container">
          <div className="content-box">
            <h2>Actualités</h2>
            <img src="/images/gang2girafes2024.jpg" alt="Actualités" />
            <p>Une nouvelle saison qui s'annonce encore une fois riche en émotion et en partage.</p>
          </div>
          <div className="content-box">
            <h2>Nos Coachs</h2>
            <img src="/images/coachs.jpg" alt="Nos Coachs" />
            <p>Audric le mardi, Fabien le jeudi, nous concoctent un programme permettant à chacun de progresser dans une bonne ambiance.</p>
          </div>
          <div className="content-box">
            <h2>Nos Sponsors</h2>
            <img src="/images/Girafe_Cool.png" alt="Nos Sponsors" />
            <p>Ils nous soutiennent et nous accompagnent.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;