import React from 'react';
import { Link } from 'react-router-dom';
import { FaLock } from 'react-icons/fa'; // Importez l'icône de cadenas
import './Header.css';

const Header = () => {
  return (
    <header className="main-header">
      <div className="container header-content">
        <div className="logo-container">
          <img src="/images/logo_TCR_FdBlanc.png" alt="Trail City Run Montpellier" className="logo" />
        </div>
        <nav>
          <ul>
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/equipe">Équipe</Link></li>
            <li><Link to="/calendrier">Calendrier</Link></li>
            <li><Link to="/actualites">Actualités</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li className="login-link">
              <Link to="/login" aria-label="Connexion">
                <FaLock />
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;