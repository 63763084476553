import React from 'react';
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="container footer-content">
        <div className="social-links">
          <a href="https://www.instagram.com/tcrmontpellier/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
          </div>
          <div>
          <p className="slogan">"On est pas là pour coiffer les girafes !"</p>
          </div>
          <div className="social-links">
          <a href="https://www.facebook.com/TraCRMtp?locale=fr_FR" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebookF />
          </a>
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;